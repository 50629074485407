
<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { authHeader } from '../../../helpers/fakebackend/auth-header';

import {
  required 
} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Wallet Points Settings",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
   Multiselect,
    Layout,
    PageHeader,
    
  },
  data() {
    return {
      title: "Wallet Points Settings",
      items: [
        {
          text: "Wallet Points Settings",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],


  
      status :'',
      submitted: false,
      showDismissibleAlert :false,
      loading: false,
      adminsArr:[],
      corpAdminID:[],
      settings:"1",
      amount:[],
    

    };
  },

    validations: {
    settings:{
        required,
    },
    corpAdminID:{
        required:function() {
                return (this.settings==2 && this.corpAdminID.lenght<0) ? false : true;
        },
    },
    amount:{
        required:function() {
                return (this.settings==1 && this.amount=="") ? false : true;
        },
    },
   
  },
  methods:{

    submitForm(e){

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
          let formData = new FormData();  
  
            formData.append('amount', this.amount);
            formData.append('corpAdminIDs',JSON.stringify(this.corpAdminID));
            formData.append('settings', this.settings);
            formData.append('corporateID', this.$storageData.profile.corporateID);
           // formData.append('createdBy', this.$storageData.profile.pid);
            //formData.append('loginTypeID',this.$storageData.login_type);
    
      e.preventDefault();
      this.axios.post(this.$loggedRole+"/addWalletSettings",  formData, {
          headers: authHeader()
        })
      .then((result)=>{
        this.status = result.data.data;
              this.showDismissibleAlert = true;
              this.loading = false;

              setTimeout(() => {
                  this.$router.push({name: 'categoryTable'}); 
              },2000);
        
         // console.warn(result);

      })
      .catch(()=> {
       this.loading = false;
                       
                        alert("Something went Wrong");
                    });
      }  
    },
  getEmployees(){
    this.adminsArr = [];

    this.axios.post(this.$loggedRole+"/getEmployeesByCorporateID", {
        corporateID:  this.$storageData.profile.corporateID
      }).then((result)=>{
                this.adminsArr = result.data.data;
                
        });

  },
  

  },
mounted(){

    this.getEmployees();
  },

  middleware: "authentication",
};
</script>

<template lang="en">
<Layout>
    <PageHeader :title="title" :items="items" />
      <div class="alertMsg">
     <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
        Settings changed Successfully!
    </b-alert>
  </div>
<form @submit.prevent="submitForm" method="post">

        <div class="col-lg-8">
            <div class="card">
                <div class="card-body">
                            <div class="row">
                                    <div class="col-md-6">
                                        <b-form-radio
                                            v-model="settings"
                                            class="custom-radio mb-3"
                                            value="1"
                                            plain
                                            > Add manually</b-form-radio
                                        >
                                    
                                    </div>

                                    <div class="col-md-6 mb-3" v-if="settings==1">
                                            <label>Choose Admins* </label>
                                            <multiselect v-model="corpAdminID" :options="adminsArr" track-by="corpAdminID" label="adminName" 
                                            :show-labels="false" :multiple="true" :class="{
                                                'is-invalid': submitted && $v.corpAdminID.$error,
                                            }" ></multiselect>
                                            <div
                                            v-if="submitted && $v.corpAdminID.$error"
                                            class="invalid-feedback"
                                            >
                                            <span v-if="!$v.corpAdminID.required"
                                                >This value is required.</span
                                            >
                                            </div>
                                    </div>

                            </div>
                            <div class="row">
                                    <div class="col-md-6">
                                        <b-form-radio
                                            v-model="settings"
                                            class="custom-radio mb-3"
                                            value="2"
                                            plain
                                            > Add on Attendance</b-form-radio
                                        >
                                    
                                    </div>

                                    <div class="mb-3 col-md-4" v-if="settings==2">
                                        <label class="form-label" for="formrow-amount-input">Enter amount to add *</label>
                                            <div class="input-group"> <div class="input-group-text">₹</div> 
                                            <input type="text" class="form-control" id="formrow-amount-input" name="amount" 
                                            v-model="amount"
                                            :class="{
                                                'is-invalid': submitted && $v.amount.$error,
                                            }" >
                                            </div>
                                            <div
                                            v-if="submitted && $v.amount.$error"
                                            class="invalid-feedback"
                                            >
                                            <span v-if="!$v.amount.required"
                                                >This value is required.</span
                                            >
                                            </div>
                                        </div>
                            </div>
          
              <div class =" row" style="float:right;">
                    <b-spinner v-if="loading"
                    class="m-2 col-3"
                    variant="primary"
                    role="status"
                  ></b-spinner>
                  <button type="submit" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit</button>
              </div>
        </div>
               
            </div>
        </div>

</form>


  

</Layout>
</template>
<style>
.alertMsg{
  position: fixed;
    right: 0px;
    z-index: 500;
    top: 79px;
    width: 22%;
}
</style>


